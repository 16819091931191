<template>
  <v-container fluid>
    <v-card-title class="text-center teal lighten-3 accent-4 white--text">
      Entregados
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Búsqueda"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer />
    </v-card-title>
    <v-tabs v-model="selectedTab" color="teal lighten-3 accent-4" left>
      <v-tab
        v-for="(sucursal, index) in itemsSucursalesDisponibles"
        :key="index"
      >
        <div v-if="sucursal.sucursal === 'LaPaz'">La Paz</div>
        <div v-else-if="sucursal.sucursal === 'Obregon'">Obregón</div>
        <div v-else>
          {{ sucursal.sucursal }}
        </div>
      </v-tab>
      <v-tab-item v-for="(sucursal, index) in itemsNombre" :key="index">
        <v-data-table
          :headers="headers"
          :items="selectedItems"
          :search="search"
          :footer-props="{ 'items-per-page-text': 'Envíos por página' }"
          :header-props="{ 'sort-by-text': 'Ordenar por' }"
          no-data-text="No hay envíos entregados"
          no-results-text="No hay envíos que coincidan con la búsqueda"
          :item-class="itemRowBackground"
        >
          <!-- <template v-slot:[`item.creado_por`]="{ item }">
                {{ getNombreUsuario(item.creado_por) }}
              </template> -->
          <template v-slot:[`item.fecha_creacion`]="{ item }">
            {{ item.fecha_creacion.slice(0, item.fecha_creacion.indexOf("T")) }}
          </template>
          <template v-slot:[`item.fecha_recibido`]="{ item }">
            {{ item.fecha_recibido.slice(0, item.fecha_recibido.indexOf("T")) }}
          </template>
          <template v-slot:[`item.lista_productos`]="{ item }">
            <v-list-group v-bind:class="itemRowBackground(item)">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-subtitle>Productos</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="producto in item.productos.lista_de_productos"
                :key="producto.codigo_producto"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      producto.codigo_producto +
                      " " +
                      producto.nombre_producto +
                      ": " +
                      producto.cantidad
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
//   import axios from "axios";
export default {
  props: ["productos", "usuarios", "enviosEntregados"],
  data() {
    return {
      selectedTab: 0,
      search: "",
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Transportista",
          value: "transportista",
        },
        {
          text: "Fecha de Creación",
          value: "fecha_creacion",
        },
        {
          text: "Creado por",
          value: "creado_por",
        },
        {
          text: "Estado",
          value: "estado",
        },
        {
          text: "Fecha de Recibido",
          value: "fecha_recibido",
        },
        {
          text: "Discrepancias",
          value: "discrepancias",
        },
        {
          text: "Destino",
          value: "destino",
        },
        {
          text: "Documentos",
          value: "documentos",
        },
        {
          text: "Lista de Productos",
          value: "lista_productos",
        },
        {
          text: "Fecha de Llegada",
          value: "fecha_llegada",
        },
        {
          text: "Guía",
          value: "guia",
        },
        {
          text: "Recibido por",
          value: "recibido_por",
        },
        {
          text: "Observaciones",
          value: "observaciones",
        },
        {
          text: "Origen",
          value: "origen",
        },
      ],
      sucursales: [
        { sucursal: "Mexicali" },
        { sucursal: "Tijuana" },
        { sucursal: "Ensenada" },
        { sucursal: "La Paz" },
        { sucursal: "Obregon" },
        { sucursal: "Hermosillo" },
      ],
      ordenSucursales: [
        "Mexicali",
        "Tijuana",
        "Ensenada",
        "LaPaz",
        "Obregon",
        "Hermosillo",
      ],
      itemsSucursalesDisponibles: [],
    };
  },
  methods: {
    getProductosPorDocumento(documento) {
      return this.enviosEntregados[documento] || [];
    },

    obtenerArregloSucursales() {
      let arrayEnvios = this.enviosEntregados;
      const nuevoArreglo = arrayEnvios.sort((a, b) => {
        const indiceA = this.ordenSucursales.indexOf(a.sucursal);
        const indiceB = this.ordenSucursales.indexOf(b.sucursal);

        return indiceA - indiceB;
      });

      this.itemsSucursalesDisponibles = nuevoArreglo;
    },
    getVendedor(vendedorId) {
      const vendedorEncontrado = this.usuarios.find(
        (usuario) => usuario.id === vendedorId
      );
      if (vendedorEncontrado) {
        return vendedorEncontrado.nombre + " ";
      }
      return "";
    },
  },
  computed: {
    itemsNombre() {
      return this.itemsSucursalesDisponibles.map((sucursal) => {
        const envios = sucursal.envios.map((envio) => {
          const nuevoEnvio = { ...envio };
          nuevoEnvio.creado_por = this.getVendedor(envio.creado_por);
          nuevoEnvio.recibido_por = this.getVendedor(envio.recibido_por);
          if (nuevoEnvio.origen === "LaPaz") nuevoEnvio.origen = "La Paz";
          if (nuevoEnvio.destino === "LaPaz") nuevoEnvio.destino = "La Paz";

          if (nuevoEnvio.origen === "Obregon") nuevoEnvio.origen = "Obregón";
          if (nuevoEnvio.destino === "Obregon") nuevoEnvio.destino = "Obregón";

          return nuevoEnvio;
        });

        return { ...sucursal, envios };
      });
    },
    productNames() {
      const products = this.productos; // replace with the actual list of products
      const productMap = {};
      for (const product of products) {
        productMap[product.codigo_producto] = product.nombre_producto;
      }
      return productMap;
    },

    getDocumentoProducto() {
      return (documento) => {
        const producto = this.itemsEnvios.find(
          (p) => p.codigo_producto === documento
        );
        return producto ? producto.nombre_producto : "";
      };
    },
    // getNombreUsuario() {
    //   return (creado_por) => {
    //     const usuario = this.usuarios.find((u) => u.id === creado_por);
    //     return usuario ? usuario.nombre : "";
    //   };
    // },
    selectedItems() {
      return this.itemsNombre[this.selectedTab].envios;
    },
    // selectedItems() {
    //   const selectedDestino = this.sucursales[this.selectedTab].sucursal;
    //   return this.items.filter((item) => item.destino === selectedDestino);
    // },
    itemRowBackground() {
      return (item) => {
        const estado = item.estado;
        switch (estado) {
          case "Recibido":
            return "green lighten-3";
          default:
            return "";
        }
      };
    },
  },
  mounted() {
    this.obtenerArregloSucursales();
  },
  components: {},
};
</script>
