<template>
  <div>
    <h3>{{ title }}</h3>
    <bar-chart :data="chartData" :options="chartOptions"></bar-chart>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels
);

export default {
  components: { BarChart: Bar },
  props: {
    title: {
      type: String,
      required: true,
    },
    sucursalesData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    chartData() {
      const labels = this.sucursalesData.map(
        (data) => `${data.sucursal} | Stock: ${data.stock.toFixed(2)}`
      );
      const inventarioData = this.sucursalesData.map(
        (sucursal) => sucursal.inventario
      );
      const enviosData = this.sucursalesData.map((data) => data.envios);
      const pronosticoData = this.sucursalesData.map((data) => data.pronostico);
      const faltantesData = this.sucursalesData.map((data) => data.faltantes);
      return {
        labels: labels,
        datasets: [
          {
            label: "Inventario",
            backgroundColor: "#2191ee",
            data: inventarioData,
            stack: "Inventario",
          },
          {
            label: "Envios",
            backgroundColor: "#4bc0c0",
            data: enviosData,
            stack: "Inventario",
          },
          {
            label: "Pronostico",
            backgroundColor: "#f87979",
            data: pronosticoData,
            stack: "Pronostico",
          },
          {
            label: "Faltantes",
            backgroundColor: "#c11515",
            data: faltantesData,
            stack: "Pronostico",
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          y: {
            beginAtZero: true,
          },
          x: {
            stacked: true,
          },
        },
        plugins: {
          datalabels: {
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0;
            },
            color: "white",
            aling: "center",
            anchor: "center",
            font: {
              size: 12,
              weight: "bold",
            },
            textStrokeColor: "black",
            textStrokeWidth: 3,
            formatter: function (value) {
              return value;
            },
            clip: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var label = context.dataset.label || "";
                if (label) {
                  label += ": ";
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y;
                }
                return label;
              },
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped></style>
