<template>
  <v-dialog max-width="90%" v-model="dialog">
    <v-card
      ><v-card-title
        >Nuevo Envío <v-spacer></v-spacer>
        <v-btn icon value="log in" color="blue darken-1" text @click="cerrar()">
          <v-icon dark> mdi-close </v-icon>
        </v-btn></v-card-title
      >
      <v-card-text>
        <v-container>
          <form ref="form" @submit.prevent="crearPerfil()">
            <v-row>
              <v-col>
                <v-select
                  label="Origen"
                  outlined
                  :items="origenes"
                  v-model="origen"
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  label="Destino"
                  outlined
                  :items="sucursales"
                  v-model="destino"
                  required
                ></v-select> </v-col
            ></v-row>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  outlined
                  label="Documento"
                  v-model="documentos"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="mt-3">
                <v-btn color="primary" @click="getProductoDocumento">
                  Agregar Documento
                </v-btn>
              </v-col>
              <v-list>
                <v-list-group
                  v-for="(item, index) in items"
                  :key="index"
                  :value="false"
                >
                  <template v-slot:activator>
                    <v-list-item class="fondo">
                      <v-list-item-content>
                        <v-list-item-title>
                          Documento: {{ item.documento }}
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-btn
                          icon
                          @click="eliminarDocumento(index)"
                          color="red"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <v-list-item
                    v-for="(producto, prodIndex) in item.productos"
                    :key="prodIndex"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ producto.codigo_producto }}:
                        {{ producto.nombre_producto }}: {{ producto.cantidad }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-row>

            <v-row class="ml-1">
              <!-- <v-col cols="2"> -->
              <AgregarListaProductos
                @enviar-datos="obtenerProductosDocumento"
              ></AgregarListaProductos>
              <!-- </v-col> -->
              <!-- <v-col cols="2"> -->

              <!-- </v-col> -->
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  outlined
                  label="Transportista"
                  :items="transportistas"
                  append-icon="mdi-truck-delivery-outline"
                  v-model="transportista"
                  required
                ></v-select>
                <v-text-field
                  outlined
                  label="Guía"
                  append-icon="mdi-qrcode"
                  v-model="guia"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  label="Fecha de llegada"
                  append-icon="mdi-calendar"
                  v-model="fecha_llegada"
                  @focus="showDatePicker = true"
                  readonly
                  required
                ></v-text-field>
                <v-card v-if="showDatePicker">
                  <v-card-title class="justify-center"
                    >Selecciona un día</v-card-title
                  >
                  <v-row justify="center">
                    <v-date-picker
                      no-title
                      color="light-green"
                      v-model="fecha_llegada"
                      :min="nowDate"
                      locale="es-MX"
                      @input="showDatePicker = false"
                    >
                    </v-date-picker>
                  </v-row>
                </v-card>
              </v-col>
              <v-col>
                <v-textarea
                  outlined
                  label="Observaciones"
                  v-model="observaciones"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-container
              ><v-btn type="submit" color="teal lighten-2" text
                >Crear envío</v-btn
              ></v-container
            >
          </form>
        </v-container>
      </v-card-text>
    </v-card>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="red lighten-2" dark class="mr-2" v-on="on" v-bind="attrs">
        Crear Envío
      </v-btn>
    </template>
  </v-dialog>
</template>
<script>
import axios from "axios";
import AgregarListaProductos from "../AgregarListaProductos.vue";
export default {
  props: ["allProducts"],
  data() {
    return {
      date: undefined,
      nowDate: new Date().toISOString().slice(0, 10),
      showDatePicker: false,
      textoAreaTexto: "",
      codigo_producto: "",
      cantidad_producto: "",
      destino: "",
      origen: "",
      cantidad: 1,
      transportista: "",
      guia: "",
      observaciones: "",
      fecha_llegada: "",
      dialog: false,
      transportistas: [],
      productos: [],
      sucursales: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
      ],
      origenes: [
        {
          text: "Mexicali",
          value: "Mexicali",
        },
        {
          text: "Tijuana",
          value: "Tijuana",
        },
        {
          text: "Ensenada",
          value: "Ensenada",
        },
        {
          text: "La Paz",
          value: "LaPaz",
        },
        {
          text: "Obregón",
          value: "Obregon",
        },
        {
          text: "Hermosillo",
          value: "Hermosillo",
        },
        {
          text: "Proveedor",
          value: "proveedor",
        },
      ],
      errores: [],
      items: [],
      documentos: "",
    };
  },
  methods: {
    eliminarDocumento(index) {
      this.items.splice(index, 1);
    },

    async getProductoDocumento() {
      const { serie, folio } = this.transformarStringAObjeto(this.documentos);
      const sucursal = this.determinarSucursal();
      console.log("EJEMPLO", serie, folio, sucursal);

      if (!serie || !folio || !sucursal) {
        console.error("Datos incompletos para realizar la consulta");
        return;
      }

      if (this.items.some((item) => item.documento === `${serie}-${folio}`)) {
        alert("Ya existe este documento, agregue otro diferente.");
        return;
      }

      try {
        const response = await axios.get(
          `/reportes/productos_de_documento?sucursal=${sucursal}&serie=${serie}&folio=${folio}`
        );
        const productos = response.data.productos_de_documento;
        console.log(productos);
        alert("Documento cargado");

        this.items.push({
          documento: `${serie}-${folio}`,
          productos: productos,
        });
      } catch (error) {
        console.error(error);
      }
    },

    actualizarProductos() {
      this.getProductoDocumento();
      console.log(this.formatText());
    },
    transformarStringAObjeto(stringProducto) {
      const regex = /^(.+)-(\d+)$/;
      const match = stringProducto.match(regex);

      if (!match) {
        console.error("Formato de documento incorrecto");
        return { serie: null, folio: null };
      }

      const serie = match[1];
      const folio = match[2];

      return { serie, folio };
      /*const [codigo_producto, nombre_producto, cantidad] =
        stringProducto.split("~");

      return {
        cantidad,
        codigo_producto,
        nombre_producto,
      };*/
    },
    determinarSucursal() {
      if (this.origen === "proveedor") {
        return this.destino;
      }
      return this.origen;
    },
    // Modificar metodo addObject para que agregue el producto y la cantidad al textArea con el formato correcto
    addProduct() {
      const expresionRegular = /\n/;
      const nuevoProducto = `${this.codigo_producto}~${this.cantidad_producto}`;

      if (expresionRegular.test(this.textoAreaTexto)) {
        //textoArea texto tiene salto de liena entonces hay mas productos
        this.textoAreaTexto = this.textoAreaTexto + "\n" + nuevoProducto;
      } else if (this.textoAreaTexto === "") {
        //textoAreaTexto esta vacio, asi que solo se le asigna el valor nuevo
        this.textoAreaTexto = nuevoProducto;
      } else {
        //textoAreaTexto tiene un solo producto
        this.textoAreaTexto = this.textoAreaTexto + "\n" + nuevoProducto;
      }
      console.log(this.productos);
      console.log(this.formatText());
    },
    validarEntero() {
      this.cantidad_producto = this.cantidad_producto.replace(/\D/g, "");
      // console.log(this.cantidad_producto);
    },
    formatText() {
      const lines = this.textoAreaTexto.split("\n");
      const result = [];

      for (const line of lines) {
        const parts = line.split("~");

        if (parts.length === 3) {
          const code = parts[0];
          const quantity = parts[2];

          result.push(`${code}:${quantity}`);
        }
      }

      return result.join(",");
    },
    limpiarTextArea() {
      this.productos = [];
      this.textoAreaTexto = "";
      console.log(this.productos);
    },
    obtenerProductosDocumento(mensaje) {
      // Realiza cualquier manipulación de datos necesaria en el padre
      const expresionRegular = /\n/;
      this.productos = [];
      this.productos.push(...mensaje);
      console.log(this.productos);
      const productosDocumento = this.productos
        .map((objeto) => {
          return `${objeto.codigo_producto}~${objeto.nombre_producto}~${objeto.cantidad}`;
        })
        .join("\n");
      console.log(this.formatText());
      if (expresionRegular.test(this.textoAreaTexto)) {
        //textoArea texto tiene salto de liena entonces hay mas productos
        this.textoAreaTexto = this.textoAreaTexto + "\n" + productosDocumento;
      } else if (this.textoAreaTexto === "") {
        //textoAreaTexto esta vacio, asi que solo se le asigna el valor nuevo
        this.textoAreaTexto = productosDocumento;
      } else {
        //textoAreaTexto tiene un solo producto
        this.textoAreaTexto = this.textoAreaTexto + "\n" + productosDocumento;
      }
    },
    async getTransportistas() {
      try {
        const response = await axios.get(
          `varios/get_clasificaciones?tipo=transportista`
        );
        this.transportistas = response.data.valores;
      } catch (error) {
        console.error(error);
      }
    },
    increaseCantidad() {
      this.cantidad += 1;
    },
    decreaseCantidad() {
      if (this.cantidad > 1) {
        this.cantidad -= 1;
      }
    },
    async crearPerfil() {
      const documentos = this.items.map((item) => item.documento);

      const payload = {
        origen: this.origen,
        destino: this.destino,
        documentos: documentos,
        transportista: this.transportista,
        guia: this.guia,
        observaciones: this.observaciones,
        fecha_llegada: this.fecha_llegada,
      };
      console.log(payload);
      const response = await axios
        .post(`/envios/crear`, payload)
        .catch((error) => {
          if (error) {
            this.errores.push(error);
            console.log(JSON.stringify(error.response.data));
            if (error.response.data.detail[0].loc[1] === "lista_productos") {
              alert(
                // "El formato para 'Lista de productos' es el siguiente:\nCODIGOPRODUCTO:cantidad en numero\nPor ejemplo:\n'BAYEP400:20'"
                `Error agregando productos:\n${error.response.data.detail[0].msg}`
              );
            } else if (error.response.data.detail[0].loc[1] === "origen") {
              alert(JSON.stringify(error.response.data.detail[0].msg));
            } else {
              alert(
                "Asegurate de llenar los campos de 'Destino', 'Transportista' y 'Fecha de llegada'"
              );
            }
          }
          this.serverError = true;
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
      console.log(JSON.stringify(response.data));
      alert("Envío creado");
      this.$emit("envioCreado");
      // location.reload();
      this.cerrar();
    },
    cerrar() {
      this.dialog = !this.dialog;
    },
  },
  computed: {
    productosNombreCodigo() {
      return this.allProducts.map((entry) => {
        const codigoDescription =
          entry.codigo.length > this.descriptionLimit
            ? entry.codigo.slice(0, this.descriptionLimit) + "..."
            : entry.codigo;
        const nombreDescription =
          entry.nombre.length > this.descriptionLimit
            ? entry.nombre.slice(0, this.descriptionLimit) + "..."
            : entry.nombre;
        return Object.assign({}, entry, {
          Description: `${codigoDescription}~${nombreDescription}`,
        });
      });
    },
  },
  created() {
    this.getTransportistas();
  },
  components: { AgregarListaProductos },
};
</script>

<style scoped>
.quantity-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.fondo {
  background-color: rgba(43, 129, 214, 0.4);
  margin: 0.2rem;
  border-radius: 5px;
  color: white;
}
</style>
